p7{
    /* background:#111; */
    font-weight: bold;
    color:#111;
    text-shadow:1px 1px 10px #fff, 1px 1px 10px #ccc;
    font-size:48px;
    text-align:center;
  }
  .home{
    text-align:center;
  }