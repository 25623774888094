 .header{

    color:rgb(7, 37, 63);
  
   text-align: center;
  
  }
  
  .container{
  
    text-align: center;
  
    margin-left: auto;
  
    margin-right: auto;

    padding: 16px;
  
    background-color: rgba(115, 205, 253, 0.525);
  
  }
  
  
  
  body {
  
    font-family: Arial, Helvetica, sans-serif;
  
    background-color: rgb(193, 232, 239);
  
  }
  
  
  
  * {
  
    box-sizing: border-box;
  
  }
  /* .container {

    padding: 16px;
  
    background-color: rgb(255, 255, 255);
  
  } */
  
  
  
  
  
  input[type=text], input[type=password],input[type=email] {
  
    width: 30%;
  
    padding: 8px;
  
    margin: 2px 0 2px 0;
  
    display: inline-block;
  
    border: 15px;
  
    background: #f1f5f6;
  
  }
  
  
  
  /*input[type=text]:focus, input[type=password]:focus {
  
    background-color: rgba(245, 245, 245, 0.703);
  
    outline: none;
  
  }*/

  hr {

    border: 1px solid #283922;
  
    margin-bottom: 25px;
  
  }
  
  
  
  
  
  .registerbtn {
    
  
    background-color: #83a096;
  
    color: white;
  
    padding: 8px 20px;
  
    margin: 8px 0;
  
    border: none;
  
    cursor: pointer;
  
    width: 20%;
  
    opacity: 0.5;
  
  }
  
  
  
  .registerbtn:hover {
  
    opacity: 1;
  
  }
  
  
  
  
  
  a {
  
    color: dodgerblue;
  
  }
  
  
  
  .signin {
  
    background-color: #f1f1f1;
  
    text-align: center;
  
  }
  
  .Apps {
  
    position:center;
  
    /* top: 15%;
  
    left: 35%; */
  
    width:fit-content;
  
    height:auto;
  
    margin: auto; 
  
    border: 1px solid #ccc;
  
    background-color: white;
  
  } 
  
  .field{
    margin-left: 35px;

  } 


/* CSS */
.button {
  align-items: center;
  background-image: linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  
  min-width: 140px;
  padding: 19px 24px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  padding: 8px 20px;
  
  margin: 8px 0;

  border: none;

  cursor: pointer;

  width: 20%;

  opacity: 0.5;
  margin-left: auto;
  margin-right: auto;
}

.button:active,
.button:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .button {
    font-size: 16px;
    min-width: 20%;
  }
}